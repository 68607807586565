// http://ionicframework.com/docs/theming/
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700");

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/pagination";
@import "@ionic/angular/css/ionic-swiper";

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "theme/custom-ion-colors.scss";
// Add base app styles
@import "theme/app-defaults.scss";
// Add base shell styles
@import "theme/shell-defaults.scss";

ion-popover {
  --width: 240px;
  padding: 4px;
}

#selectAllDiv {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  
  ion-label {
    margin-left: 10px;
  }
}

@media print {
    
  ion-backdrop,
  .tabbar,
  ion-footer,
  ion-content::part(background) {
    display: none !important;
  }

  ion-nav {
    contain: none;
    overflow: visible;
  }

  ion-split-pane {
    display: block;
  }

  .scroll-content,
  ion-modal,
  ion-modal .modal-wrapper,
  ion-modal .ion-page,
  ion-modal .ion-page>ion-content,
  .ion-page,
  .ion-page>ion-content,
  ion-split-pane,
  ion-tab,
  ion-tabs,
  ion-router-outlet,
  .app-root,
  ion-content::part(scroll),
  body {
    contain: none;
    position: initial;
    height: auto;
    overflow: visible;
  }

  .fixed-content, .scroll-content {
    margin-top: 0 !important;
  }
}

body {
  background: linear-gradient(
    to bottom right,
    rgba(242, 247, 254, 1),
    rgba(246, 250, 252, 1)
  );
}

ion-button {
  --border-radius: 5px;
}

ion-content {
  --background: linear-gradient(
    to bottom right,
    rgba(242, 247, 254, 1),
    rgba(246, 250, 252, 1)
  );
  overflow: hidden;
}

$liaison-card-height: 450px;
$liaison-card-width: 340px;

* {
  font-family: "Cabin";
  font-weight: 600;
}

ion-input {
  font-family: "Cabin" !important;
}

.footer-md::before {
  display: none;
}

ion-popover {
  --width: 240px;
  padding: 10x;
}

ion-modal.full-width-full-height-modal {
  --height: 100vh;
  --width: 100vw;
  --min-width: 100vw;
  --max-width: 100vw;
  margin-top: 0vh;
  box-shadow: 0px 1px 9px rgba(164, 164, 164, 0.25);
}

ion-modal.min-width-modal {
  --width: fit-content;
  --min-width: 90%;
  --max-width: 98%;
  --height: fit-content;
  --border-radius: 16px;
}

ion-modal.modal-no-max-min {
  --width: fit-content;
  --height: fit-content;
  --border-radius: 16px;
}

.min-width-modal-centered {
  --width: fit-content;
  --min-width: 90%;
  --max-width: 98%;
  --height: fit-content;
  --border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .alert-button {
    font-size: 14px !important;

    span {
      font-size: 14px !important;
    }
  }

  .alert-message {
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}

.alert-button-danger {
  color: var(--qas-red) !important;
  --color: var(--qas-red) !important;
}

@media print {
  ion-modal.min-width-modal {
    --max-width: 100% !important;
    --border-radius: 0px !important;
    --min-width: 100% !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

ion-modal.width-92-modal {
  --width: fit-content;
  --min-width: 90%;
  --max-width: 92%;
  --height: fit-content;
  --border-radius: 16px;
}

ion-modal.full-width-modal {
  --width: 100vw;
  --min-width: 100vw;
  --max-width: 100vw;
  margin-top: 0vh;
  box-shadow: 0px 1px 9px rgba(164, 164, 164, 0.25);
}

ion-modal.width-70-percent-modal {
  --width: fit-content;
  --max-width: 70%;
  --height: fit-content;
  --border-radius: 16px;
  --max-height: 70%;
}

ion-modal.width-90-percent-modal {
  --width: fit-content;
  --max-width: 90%;
  --height: fit-content;
  --border-radius: 16px;
  --max-height: 70%;
}

ion-modal.width-max-content {
  --width: max-content;
  //--min-width: 60%;
  --height: fit-content;
  --border-radius: 16px;
}

ion-modal.max-width-modal {
  --width: 90%;
  --height: 100%;
  --max-height: 404px !important;
  --max-width: 340px !important;
  --border-radius: 16px;
}

ion-modal.max-width-460-modal {
  --width: 90%;
  --height: 100%;
  --max-height: 460px !important;
  --max-width: 340px !important;
  --border-radius: 16px;
}

ion-modal.max-width-460-modal-extended-height {
  --width: 90%;
  --max-width: 340px !important;
  --border-radius: 16px;
  // --height: 540px !important;
  // --max-height: 750px !important;
  --background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  .ion-page {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.grey-background {
  background: #00000060 !important;
}

.bottom-modal {
  height: 400px;
  bottom: 0;
  top: auto;
  --backdrop-opacity: 0.0;

  ion-backdrop {
    background: transparent;
    --backdrop-opacity: 0.0;
  }
}

ion-popover.calendar {
  --width: max-content !important;

  .popover-arrow {
      display: none !important;
  }

  .popover-wrapper {
      z-index: 0;
  }
}

.textarea-wrapper {
  height: 100%;
}

ion-app.cameraView,
ion-app.cameraView ion-content,
ion-app.cameraView .nav-decor,
ion-app.cameraView ion-page {
  background: transparent none !important;
  --background :transparent none !important;
  --ion-background-color: none; // this is important
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

body.scanner-active {
  background: transparent !important;
  --background: transparent !important;
  --ion-background-color: transparent !important;

  ion-modal {
    z-index: -1 !important;
  }

  #stay-on-scanner {
    z-index: 200001 !important;
  }

  .stay-on-scanner {
    z-index: 200001 !important;
  }

  #hide-on-camera {
    display: none !important;
  }

  #show-on-camera {
    display: block !important;
  }

  ion-content {
    display: none !important;
    --background: transparent !important;
  }
  // ion-app.scanner-hide {
  //   visibility: hidden;
  //   opacity: 0;
  // }
}


ion-alert.custom-alert {
  --backdrop-opacity: 0.7;
}

ion-alert.large-max-width-alert {
  .alert-wrapper {
    max-width: 70vw;
  }
}

.custom-alert .alert-button-group {
  padding: 8px;
}

button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

.md button.alert-button.alert-button-confirm {
  border-radius: 4px;
}

.ios .custom-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.ios button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}

.alert-button {
  span {
    text-align: center !important;
    justify-content: center !important;
  }

  .approve {
    color: green;
  }

  .no {
    color: red;
  }

  button {
    width: 45%;
  }
}

.wider-popover {
  --width: 75%;
  --max-width: 400px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

ion-checkbox {
  --background-checked: var(--qas-blue);
  --border-color-checked: var(--qas-blue);
}

.green-text {
  color: green;
}

.custom-dropdown {
  --max-height: 420px;
  --width: min-content !important;

  ion-item {
    max-height: 36px !important;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 100%;
  }
  
  ion-label {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 500px) {
  .custom-dropdown {
    ion-item {
      --padding-start: 6px;
      --inner-padding-end: 6px;

      ion-label {
        //min-width: 100%;
      }
    }
  }
}

input {
  font-size: 14px !important;
}

ion-input {
  font-size: 14px !important;
}

ion-textarea {
  font-size: 14px !important;
}

ion-select {
  font-size: 14px !important;
}

ion-toggle {
  --color: var(--qas-blue);
  --background-checked: var(--qas-blue);
  --handle-background: white !important;
  --handle-background-checked: white !important;
}

.quickbooks-input {
  border-left: 8px solid #2d9f1f !important;
}

.payentry-input {
  border-left: 8px solid #f6911f !important;
}

.ng-select-no-border {
  .ng-select-container {
    border: none !important;
    box-shadow: none !important;
    height: 38px !important;
    font-size: 14px !important;
  }
}

ng-dropdown-panel {
  font-size: 14px !important;
}

ion-refresher {
  z-index: 1000000;
}

ion-refresher-content {
  z-index: 1000000;

  .refresher-refreshing {
    .refresher-refreshing-icon {
      background-color: var(--qas-blue);
      padding: 10px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    ion-spinner {
      color: white !important;
    }
  }
}

select {
  font-size: 14px !important;
}

option[disabled] {
  display: none;
  color: grey;
}

button[disabled] {
  opacity: .9;
}

.text-color-grey {
  color: grey !important;
}

.red-text-alert-style {
  p {
    color:#FF0000 !important;
  }
}

select:disabled {
  color: #00000080 !important;
}

.view-width-50 {
  .alert-wrapper {
    width: 50vw !important;
    max-width: none !important;
  }
}

.view-height-80 {
  .alert-checkbox-group.sc-ion-alert-md {
    max-height: 80vw !important;
  }
}

.ng-dropdown-panel {
  .ng-option {
    // background-color: var(--qas-red) !important;
  }
}

.modal-explained {
  padding: 6px;

  span {
    font-size: 13px;
    color: var(--qas-blue);
  }
}